(function ($) {
"use strict";

// preloader
$(window).on('load', function () {
	$('#preloader').delay(350).fadeOut('slow');
	$('body').delay(350).css({ 'overflow': 'visible' });
})

// meanmenu
$('#mobile-menu').meanmenu({
	meanMenuContainer: '.mobile-menu',
	meanScreenWidth: "992"
});


$(window).on('scroll', function () {
	var scroll = $(window).scrollTop();
	if (scroll < 245) {
		$("#header-sticky").removeClass("sticky-menu");
	} else {
		$("#header-sticky").addClass("sticky-menu");
	}
});

// data - background
$("[data-background]").each(function () {
	$(this).css("background-image", "url(" + $(this).attr("data-background") + ")")
})

// mainSlider
function mainSlider() {
	var BasicSlider = $('.slider-active');
	BasicSlider.on('init', function (e, slick) {
		var $firstAnimatingElements = $('.single-slider:first-child').find('[data-animation]');
		doAnimations($firstAnimatingElements);
	});
	BasicSlider.on('beforeChange', function (e, slick, currentSlide, nextSlide) {
		var $animatingElements = $('.single-slider[data-slick-index="' + nextSlide + '"]').find('[data-animation]');
		doAnimations($animatingElements);
	});
	BasicSlider.slick({
		autoplay: false,
		autoplaySpeed: 10000,
		dots: false,
		fade: true,
		arrows: false,
		responsive: [
			{ breakpoint: 767, settings: { dots: false, arrows: false } }
		]
	});

	function doAnimations(elements) {
		var animationEndEvents = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';
		elements.each(function () {
			var $this = $(this);
			var $animationDelay = $this.data('delay');
			var $animationType = 'animated ' + $this.data('animation');
			$this.css({
				'animation-delay': $animationDelay,
				'-webkit-animation-delay': $animationDelay
			});
			$this.addClass($animationType).one(animationEndEvents, function () {
				$this.removeClass($animationType);
			});
		});
	}
}
mainSlider();


// testimonail-active
$('.testimonial-active').slick({
  dots: false,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-chevron-left"></i></button>',
  nextArrow: '<button type="button" class="slick-next"><i class="fas fa-chevron-right"></i></button>',
  responsive: [
    {
      breakpoint: 992,
      settings: {
        arrows: false,
      }
    },
  ]
});

// brand-active
$('.brand-active').slick({
  dots: false,
  infinite: true,
  arrows: false,
  speed: 800,
  slidesToShow: 5,
  slidesToScroll: 2,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 3,
      }
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
});

// hosting-active
$('.hosting-active').slick({
  dots: false,
  infinite: true,
	arrows: true,
	speed: 800,
	prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-arrow-left"></i></button>',
	nextArrow: '<button type="button" class="slick-next"><i class="fas fa-arrow-right"></i></button>',
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    },
  ]
});


// testimonial-active
$('.s-testimonial-active').slick({
  dots: false,
  infinite: true,
  arrows: false,
  speed: 800,
  slidesToShow: 2,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
});


// testimonial-active
$('.t-testimonial-active').slick({
  dots: false,
  infinite: true,
  arrows: true,
  prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-chevron-left"></i></button>',
  nextArrow: '<button type="button" class="slick-next"><i class="fas fa-chevron-right"></i></button>',
  speed: 800,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
      }
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
      }
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
      }
    }
  ]
});

// active
$('.five-single-features,.f-pricing-box').on('mouseenter', function () {
  $(this).addClass('active').parent().siblings().find('.five-single-features,.f-pricing-box').removeClass('active');
})

// server-active
$('.server-active').owlCarousel({
  loop:true,
  margin:15,
  items:3,
  center: true,
  autoplay: false,
  autoplayTimeout: 5000,
  autoplaySpeed: 1000,
  nav:false,
    responsive:{
        0:{
        items:1,
        center: false,
        margin: 30
        },
        575:{
            items:1,
            center: false,
            margin: 30
        },
        768:{
            items:2,
            center: false,
            margin: 30
        },
        1200:{
            items:3
        },
    }
})

// niceSelect;
 $(".selected").niceSelect();

    /* magnificPopup img view */
    $('.modal-register').magnificPopup({
        type: 'inline',
        fixedContentPos: false,
        fixedBgPos: true,

        overflowY: 'auto',

        closeBtnInside: true,
        preloader: false,

        midClick: true,
        removalDelay: 300,
        mainClass: 'mfp-zoom-in'
    });

/* magnificPopup img view */
$('.popup-image').magnificPopup({
	type: 'image',
	gallery: {
	  enabled: true
	}
});

/* magnificPopup video view */
$('.popup-video').magnificPopup({
	type: 'iframe'
});


// isotop
$('.grid').imagesLoaded( function() {
	// init Isotope
	var $grid = $('.grid').isotope({
	  itemSelector: '.grid-item',
	  percentPosition: true,
	  masonry: {
		columnWidth: '.grid-item',
	  }
	});
	// filter items on button click
	$('.portfolio-menu').on( 'click', 'button', function() {
	var filterValue = $(this).attr('data-filter');
	$grid.isotope({ filter: filterValue });
	});
});

//for menu active class
$('.portfolio-menu button').on('click', function(event) {
	$(this).siblings('.active').removeClass('active');
	$(this).addClass('active');
	event.preventDefault();
});


// scrollToTop
// $.scrollUp({
// 	scrollName: 'scrollUp',
// 	topDistance: '300',
// 	topSpeed: 300,
// 	animation: 'fade',
// 	animationInSpeed: 200,
// 	animationOutSpeed: 200,
// 	scrollText: '<i class="fas fa-angle-double-up"></i>',
// 	activeOverlay: false,
// });

// WOW active
new WOW().init();
    var options = {
        title: "Cookie",
        message: "Wir verwenden technisch notwendige Cookies auf unserer Webseite sowie externe Dienste.\n" +
            "Standardmäßig sind alle externen Dienste deaktiviert. Du kannst diese jedoch nach belieben aktivieren & deaktivieren.",
        link: "/datenschutz.html",
        acceptBtnLabel: "Akzeptieren",
        advancedBtnLabel: "Anpassen",
        cookieTypesTitle: "Akzeptierte Cookies auswählen",
        moreInfoLabel: "Mehr Informationen",
        cookieTypes: [
            {
                type: 'Analytics',
                value: 'analytics',
                description: 'Diese Cookies dienen uns für Statistiken'
            },
        ],
        uncheckBoxes: false,
        onAccept: function(){
            var myPreferences = $.fn.ihavecookies.cookie();
            if(myPreferences['analytics']) {
                loadGAonConsent()
            }
        },
    }
    $('body').ihavecookies(options);
    $("#gdpr-cookietype-analytics").prop("checked", true);
    if ($.fn.ihavecookies.preference('analytics') === true) {
        loadGAonConsent();
    }


    function loadGAonConsent(){
        window.ga=window.ga||function(){(ga.q=ga.q||[]).push(arguments)};ga.l=+new Date;
        ga('create', 'UA-162214676-1', 'auto');
        ga('set', 'anonymizeIp', true);
        ga('send', 'pageview');
        var gascript = document.createElement("script");
        gascript.async = true;
        gascript.src = "https://www.google-analytics.com/analytics.js";
        document.getElementsByTagName("head")[0].appendChild(gascript, document.getElementsByTagName("head")[0]);
    }
})(window.jQuery);
